<template>
  <img
    v-if="ad.fileUrl"
    ref="image"
    :src="ad.fileUrl"
    class="image"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import {
  useCreateAdClickStatisticMutation,
  useCreateAdImpressionStatisticMutation,
  type AdAdvertisementImageFragment,
} from "~/graphql/generated";

const props = defineProps<{
  ad: AdAdvertisementImageFragment;
  isFallback?: boolean;
}>();

// Statistics mutations
const createAdImpression = useCreateAdImpressionStatisticMutation();
const createAdClick = useCreateAdClickStatisticMutation();
const deviceId = useDeviceId();

// Ref to the image element
const image = ref<HTMLImageElement>();
const isAdVisible = useElementVisibility(image);

// Log the impression when the ad is visible
const hasLogged = ref(false);
watch(
  isAdVisible,
  (isVisible) => {
    if (hasLogged.value || !isVisible) return;

    // Log to our backend
    createAdImpression.executeMutation({
      createAdImpressionStatistic: {
        adsAdvertisementId: props.ad.id,
        deviceId: deviceId.value,
      },
    });

    // Log to Google Analytics
    event("ad_show", {
      type: props.isFallback ? "fallback" : "default",
    });
  },
  { immediate: true }
);

const handleClick = () => {
  // Log the click to our backend
  createAdClick.executeMutation({
    createAdClickStatistic: {
      adsAdvertisementId: props.ad.id,
      deviceId: deviceId.value,
    },
  });

  // Open the link in a new tab
  window.open(props.ad.displayUrl, "_blank");
};
</script>

<style lang="scss" scoped>
.image {
  max-width: 100%;
  max-height: 320px;
  object-fit: contain;
  cursor: pointer;
}
</style>
