<template>
  <div class="wrapper">
    <div class="with-title">
      <div class="title">
        <BaseText class="light-70">{{ $t("app.advertisement") }}</BaseText>
      </div>
      <div class="content">
        <AdvertisementHTML
          v-if="adToShow && adToShow.__typename === 'AdsAdvertisementHtmlEmbed'"
          :ad="adToShow"
          :is-fallback="showFallback"
        />
        <AdvertisementImage
          v-else-if="
            adToShow && adToShow.__typename === 'AdsAdvertisementImage'
          "
          :ad="adToShow"
          :is-fallback="showFallback"
        />
        <AdvertisementGoogle
          v-if="!adToShow && !showFallback"
          :display-at="displayAt"
          :class="{ hidden: showFallback }"
          @update-ad-status="showFallback = $event === 'unfilled'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  AdDisplayAt,
  AdTargetType,
  useAdsAdvertisementsQuery,
} from "~/graphql/generated";

const props = defineProps<{
  displayAt: AdDisplayAt;
}>();

const showFallback = ref(false);

// Generates a random number based on the 'displayAt' prop, so that each time the page is loaded a new random number is generated.
// We use asyncData so that the server and client both use the same random number and there won't be a hydration mismatch.
const { data: randomNumber } = await useAsyncData<number>(
  props.displayAt,
  () => new Promise((resolve) => resolve(Math.random()))
);

const { data } = await useAdsAdvertisementsQuery({
  requestPolicy: "cache-first",
  pause: !randomNumber.value,
});

const adToShow = computed(() => {
  // Filter the ads based on the displayAt prop and the targetType
  const defaultAds =
    data.value?.adsAdvertisements.filter((ad) => {
      // Filter fallback ads if needed
      if (!showFallback.value && ad.targetType === AdTargetType.Fallback)
        return false;

      // Filter ads based on the displayAt prop
      return ad.displayAt === props.displayAt;
    }) ?? [];

  if (defaultAds.length === 0) return null;

  // Return an ad based on the randomNumber and the weight of the ads
  const totalWeight = defaultAds.reduce((sum, ad) => sum + ad.weight, 0);
  let randomNum = (randomNumber.value ?? Math.random()) * totalWeight;
  let selectedAd = null;
  for (const ad of defaultAds) {
    randomNum -= ad.weight;
    if (randomNum <= 0) {
      selectedAd = ad;
      break;
    }
  }

  // Fallback selects the ad with the highest weight if none is selected somehow
  if (!selectedAd) {
    selectedAd = defaultAds.reduce((prevAd, currentAd) => {
      return currentAd.weight > prevAd.weight ? currentAd : prevAd;
    });
  }
  return selectedAd;
});
</script>

<style lang="scss" scoped>
.with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem;
  width: 100%;

  .text {
    padding: 0 1rem;
  }

  &::before,
  &::after {
    content: "";
    background-color: $light-80;
    width: 100%;
    height: 1px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  position: relative;
}
.hidden {
  position: absolute;
  top: 0;
  left: -50%;
  z-index: -1;
  opacity: 0;
}
</style>
