<template>
  <div v-if="ad.content" ref="wrapper">
    <iframe
      :id="randomUuid ?? ''"
      ref="iframe"
      class="content"
      :srcdoc="`<body>${ad.content}</body>`"
      marginheight="0"
      marginwidth="0"
      frameborder="0"
      scrolling="no"
      height="250"
      width="300"
    />
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import { v4 as uuidv4 } from "uuid";
import {
  useCreateAdClickStatisticMutation,
  useCreateAdImpressionStatisticMutation,
  type AdAdvertisementHtmlEmbedFragment,
} from "~/graphql/generated";

const props = defineProps<{
  ad: AdAdvertisementHtmlEmbedFragment;
  isFallback?: boolean;
}>();

// Statistics mutations
const createAdImpression = useCreateAdImpressionStatisticMutation();
const createAdClick = useCreateAdClickStatisticMutation();
const deviceId = useDeviceId();

// Ref to the image element
const wrapper = ref<HTMLDivElement>();
const iframe = ref<HTMLIFrameElement>();
const isVisible = useElementVisibility(iframe);

// Log the impression when the ad is visible
const hasLogged = ref(false);
watch(
  isVisible,
  (isVisible) => {
    if (hasLogged.value || !isVisible) return;

    // Log impression to our backend
    createAdImpression.executeMutation({
      createAdImpressionStatistic: {
        adsAdvertisementId: props.ad.id,
        deviceId: deviceId.value,
      },
    });

    // Log to Google Analytics
    event("ad_show", {
      type: props.isFallback ? "fallback" : "default",
    });
  },
  { immediate: true }
);

// A random guid to distinguish if the same ad is displayed twice on the page
const randomUuid = ref<string | null>(null);
onMounted(() => {
  randomUuid.value = uuidv4();
});

// If we lose focus while the ad is the active element, register a click
const windowFocus = useWindowFocus();
watch(windowFocus, (isFocused) => {
  if (isFocused) return;
  const activeEl = document.activeElement;

  if (activeEl?.id === randomUuid.value) {
    createAdClick.executeMutation({
      createAdClickStatistic: {
        adsAdvertisementId: props.ad.id,
        deviceId: deviceId.value,
      },
    });
  }
});
</script>

<style lang="scss" scoped>
.content {
  width: 300px;
  height: 250px;

  body {
    margin: 0;
  }
}
</style>
